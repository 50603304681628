export const Pricing = (props) => {
    return (
      <div id="pricing">
          <div className="container">
      <div className="row">
        {props.data && Array.isArray(props.data)
            ? props.data.map((d, i) => (
                <div className="col-sm-4">
                    <div className="card text-center">
                        <div className="title">
                            <h3>{d.Tier}</h3>
                        </div>
                        <div className="option">
                            <ul>
                                {d.AS === "Yes"?<li key="AS">Asset Streaming <i className="fa fa-check" aria-hidden="true"/></li>:""}
                                {d.BTC === "Yes"?<li key="BTC">Basis texture compression <i className="fa fa-check" aria-hidden="true"/></li>:""}
                                {d.DDA === "Yes"?<li key="DDA">Developer Dashboard <i className="fa fa-check" aria-hidden="true"/></li>:""}
                                {d.DDA === "Yes"?<li key="DDA">Analytics <i className="fa fa-check" aria-hidden="true"/></li>:""}
                                <li key="Snaps">Backup Snapshots: {d.Snaps === "Yes"? <i className="fa fa-check" aria-hidden="true"/>: d.Snaps}</li>
                                <li key="Storage">Storage: {d.Storage}</li>
                                <li key="Embedding">Embedding: {d.Embedding === "Yes"? <i className="fa fa-check" aria-hidden="true"/>: d.Embedding}</li>
                                <li key="Royalties">Royalties: {d.Royalties}</li>
                                {d.Selfhost === "Yes"? <li key="Selfhost">Self-host/Custom Branding <i className="fa fa-check" aria-hidden="true"/></li>:""}
                                {d.Support === "No"? "": <li key="Support">Support Included: {d.Support}</li>}
                                <li key="Pricing">Pricing: {d.Pricing}</li>

                            </ul>
                        </div>
                    </div>
                </div>
            )): 'loading'}
      </div>
    </div>
      </div>
    );
  };
  