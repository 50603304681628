import { Pricing } from "./pricing";

export const FAQ = (props) => {
    return (
      <div id="faq">
        <div className="container">
            
      <div className="component">
        <div className="illustration">
        </div>

        <div className="faq">
          <h1 className="faq__title">FAQ</h1>
          <dl className="faq__list">
            <div className="faq__question">
              <dt>
                <button
                  aria-expanded="false"
                  aria-controls="faq1_desc"
                  data-qa="faq__question-button"
                  className="faq__question-button"
                >
                  How much does it cost?
                </button>
              </dt>
              <dd>
                <div id="faq1_desc" data-qa="faq__desc" className="faq__desc">
                  <Pricing data = {props.data.Pricing}/>
                </div>
              </dd>
            </div>

            <div className="faq__question">
              <dt>
                <button
                  aria-expanded="false"
                  aria-controls="faq2_desc"
                  data-qa="faq__question-button"
                  className="faq__question-button font-weight-bold"
                >
                What's the difference between this and something like pixel streaming?
                </button>
              </dt>
              <dd>
                <p
                  id="faq2_desc"
                  data-qa="faq__desc"
                  className="faq__desc show-description"
                >
                We stream assets rather than pixels, we leverage your existing hardware and that comes with a few benefits, including reduced bandwidth usage, and lower costs.
                </p>
              </dd>
            </div>

            <div className="faq__question">
              <dt>
                <button
                  aria-expanded="false"
                  aria-controls="faq3_desc"
                  data-qa="faq__question-button"
                  className="faq__question-button"
                >
                  Does this work for Unity applications too?
                </button>
              </dt>
              <dd>
                <p id="faq3_desc" data-qa="faq__desc" className="faq__desc">
                  Our current focus for the platform is on supporting Unreal Engine applications, but we plan 
                  to extend our offering to other engines such as Unity and others in the near future.
                </p>
              </dd>
            </div>

            <div className="faq__question">
              <dt>
                <button
                  aria-expanded="false"
                  aria-controls="faq4_desc"
                  data-qa="faq__question-button"
                  className="faq__question-button"
                >
                  When will it be available?
                </button>
              </dt>
              <dd>
                <p id="faq4_desc" data-qa="faq__desc" className="faq__desc">
                  We are targeting to release our platform into general availability in Q1 2022.
                  You can join our Discord <a href="https://discord.gg/zUSZ3T8">here</a> for more updates.
                </p>
              </dd>
            </div>

            <div className="faq__question">
              <dt>
                <button
                  aria-expanded="false"
                  aria-controls="faq5_desc"
                  data-qa="faq__question-button"
                  className="faq__question-button"
                >
                  Do you provide ongoing support?
                </button>
              </dt>
              <dd>
                <p id="faq5_desc" data-qa="faq__desc" className="faq__desc">
                  For enterprise customers, we offer ongoing maintenance and support of hosted apps on the platform.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
        </div>
      </div>
    );
  };
  