export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Request Early Access</h2>
                <p>
                  Please fill out the questionnaire below and we will get back to you as soon as possible.
                </p>
              </div>
                <a href="https://n37lz85f4cf.typeform.com/to/KwvJYzV0" className='btn btn-custom btn-lg'>
                  Register Here
                </a>
            </div>
          </div>
          </div>
        <div className='container'>
              <div className='contact-item'>
                <h3>Contact Info</h3>
                <span>
                  <i className='fa fa-map-marker'></i> Address: {props.data ? props.data.address : 'loading'} 
                </span>
                <span>
                  <i className='fa fa-envelope-o'></i> Email: {props.data ? props.data.email : 'loading'}                
                </span>
        </div>
        <div className='col-md-12'>
          <div className='row'>
            <div className='social'>
              <ul>
                <li>
                  <a href={props.data ? props.data.facebook : '/'}>
                    <i className='fab fa-facebook'/>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.twitter : '/'}>
                    <i className='fab fa-twitter'/>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.youtube : '/'}>
                    <i className='fab fa-youtube'/>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.discord : '/'}>
                    <i className='fab fa-discord'/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id='footer'>
      <div className='container text-center'>
        <p>
          &copy; 2021 Wonder Interactive Inc.{' '}
          <a href='#contact' rel='nofollow'>          
          </a>
        </p>
      </div>
    </div>
  </div>
  )
}
