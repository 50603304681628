import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
 

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
const questionButton = Array.from(
  document.querySelectorAll(".faq__question-button")
);

function toggleDescription(event) {
  const pressedBtn = event.target;

  const btnId = pressedBtn.getAttribute("aria-controls");

  const btnExpand = pressedBtn.getAttribute("aria-expanded");

  const description = document.getElementById(btnId);

  btnExpand === "false"
    ? pressedBtn.setAttribute("aria-expanded", true)
    : pressedBtn.setAttribute("aria-expanded", false);

  description.classList.toggle("show-description");
  pressedBtn.classList.toggle("font-weight-bold");

  console.log(btnExpand);
  console.log(pressedBtn);
}

questionButton.forEach((button) =>
  button.addEventListener("click", toggleDescription)
);
